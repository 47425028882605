import React from 'react';

import { useAppConfig } from '@hooks/appConfig';

const LoginDestinationHelper: React.FC<{
  handleLoginDestination: () => Promise<void>;
}> = ({ handleLoginDestination }) => {
  const { setProcessingLogin } = useAppConfig();
  setProcessingLogin(true);
  handleLoginDestination();
  return <div />;
};

export default LoginDestinationHelper;
